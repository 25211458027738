const globalColors = {
  textMainColor: "#323232",
  textSecondaryColor: "#212121",
  textMainLightColor: "#878484",
  textSecondaryLightColor: "#fff",

  mainColor: "#323232",
  mainLightColor: "#fff",
};

export default globalColors;
